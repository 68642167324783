import React from "react";

const About = () => {
  return (
    <div id="about">
      <h2>About</h2>
      <p>
        When I first started dry brining, I was always second guessing myself on
        how much salt to be using when dry brining because, well, I can’t really
        taste the raw meat to know where I’m at. Now I’m much better at doing it
        by feel, but put a different type of salt in front of me and I’m lost
        again! That’s because different types of salt have varying densities.
        This is a problem for us silly Americans, because we tend to use
        volumetric measuring spoons rather than weighing the mass. A teaspoon of
        table salt is twice as salty as a teaspoon of diamond kosher. Keep that
        in mind next time you see a recipe that tells you to use “3 tsps of
        salt”.
      </p>
      <p>
        Take the recommended salinity percentages from this calculator with a
        grain of salt. I did some of my own testing in addition to other
        research to determine those value, but that testing was not super
        scientific and was subject to my palette. The “right amount of salt” is
        different for everyone, which is why I put in a little slider so you can
        adjust to your liking!
      </p>
    </div>
  );
};

export default About;
