export const Salts = {
  "Table Salt": {
    name: "Table Salt",
    gramsPerTbsp: 18.6
  },
  "Maldon": {
    name:"Maldon", 
    gramsPerTbsp: 8.4
  },
  "Sel Gris": {
    name: "Sel Gris",
    gramsPerTbsp: 13
  },
  "Fine Sea": {
    name: "Fine Sea",
    gramsPerTbsp: 14.6
  },
  "Morton's Kosher": {
    name: "Morton's Kosher",
    gramsPerTbsp: 14.75
  },
  "Diamond Kosher": {
    name: "Diamond Kosher",
    gramsPerTbsp: 9.75
  }
}