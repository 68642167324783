import React from 'react';

const Footer = () => {
  return (
    <footer>
        <p>Made with ❤️ from Seattle</p>
        <p>Icons by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a></p>
        <a href="https://www.buymeacoffee.com/willliuwillliu"><img className="buymeacoffee" src="https://cdn.buymeacoffee.com/buttons/v2/default-white.png" alt="Buy Me A Coffee" /></a>
    </footer>
  )
}

export default Footer

